import React from 'react'
import Layout from '../../components/layout/layout'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'

import rowImage from '../../queries/images/row-image'

import unit2Icon from '../../images/icons/unit_02_icon.svg'
import CO2MaunaNatural23 from '../../images/student-pages/unit-2/23_co2_mauna_natural_process_only.gif'
import CO21958Mauna25 from '../../images/student-pages/unit-2/25_co2_mauna_1958.gif'
import CO2Mauna26 from '../../images/student-pages/unit-2/26_co2_mauna_2004.gif'
import CO2Fraction27 from '../../images/student-pages/unit-2/27_co2_fraction.gif'


const TheBreathingBiosphere23 = (props) => (
  <Layout location={props.location.pathname}>
    <SEO title="Carbon Connections - 2.3 The Breathing Biosphere" />
    <Container className="mb-5" fluid>
      <Row>
        <Col md="auto">
          <SideBarSeTwo location={props.location.pathname} />
        </Col>
        <Col>
          <CCBreadcrumb
            pathname={props.location.pathname}
            title={'2.3 The Breathing Biosphere'}
            replace={props.replace}
          />
          <Alert
            className="w-100"
            variant="warning"
          >
            BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
          </Alert>
          <Card>
            <Card.Body>

              <ContentHeader
                icon={ unit2Icon }
                iconAlt="Unit 2 icon"
                studentPageHeader="Unit 2: Carbon Now"
                unitNumber="2.3"
                studentPageHeaderSub="The Breathing Biosphere"
                sectionA={ <>Carbon Accounting</> }
                sectionALink="/unit-2/2.3-the-breathing-biosphere/#carbon-accounting"
                sectionB={ <>Hawaiian P &amp; R</> }
                sectionBLink="/unit-2/2.3-the-breathing-biosphere/#hawaiian-p-and-r"
                sectionC={ <>The Human Effect</> }
                sectionCLink="/unit-2/2.3-the-breathing-biosphere/#the-human-effect"
                sectionD={ <>Reflect and Connect</> }
                sectionDLink="/unit-2/2.3-the-breathing-biosphere/#reflect-and-connect"
              />

              <FocusQuestion
                focusQuestionBodyText={ <>How can simulations and models help you understand the annual breathing of the biosphere?</> }
              />

              <Introduction
                paragraphOne={
                  <>
                    <Row className="d-md-none">
                      <Col>
                        <Img
                          className="rounded img-fluid mb-3"
                          fluid={props.data.image22.childImageSharp.fluid}
                          alt="Earth"
                        />
                      </Col>
                    </Row>
                    <Img
                      className="smallImageRight img-fluid d-none d-md-block ml-3 mb-1"
                      fluid={props.data.image22.childImageSharp.fluid}
                      alt="Earth"
                    />

                    <p>In Unit 2, you are exploring carbon that is moving through the Earth system. After an aquarium experiment, you are now thinking about systems as big as Earth. Photosynthesis and respiration are key natural processes in the carbon cycle. Factors such as the amount of sunlight can limit or control how fast those processes act.</p>
                  </>
                }

                paragraphTwo={
                  <>
                  <p>Another way to explore Earth's moving carbon is with models and simulations. That is what scientists do; it helps them test forcings and responses. In this lesson, you will use models of CO<sub>2</sub> levels in the atmosphere to understand the processes that move carbon. You will also be able to compare the natural processes with the human influence. In Lesson 2.3, the main learning goals are:</p>
                  <ul>
                    <li>Photosynthesis and respiration are two important processes that help in understanding the patterns of CO<sub>2</sub> in the atmosphere.</li>
                    <li>CO<sub>2</sub> in the atmosphere has a trend that is steadily rising because of human activities and the use of fossil fuels.</li>
                    <li>Scientific models and simulations help you understand the transfer of carbon by different processes in the Earth system.</li>
                  </ul>
                  </>
                }

                paragraphThree={
                  <>
                    <p>As you continue with this lesson, use the focus question to guide your learning.</p>
                  </>
                }
              />

              <ButtonTop />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="carbon-accounting" className="cardHeader">
                      <h2>
                        A. <span className="headerFontBlue">Carbon Accounting</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Maybe you've heard the idea to "plant a tree" to reduce CO<sub>2</sub> in the atmosphere. This may be true if only trees did photosynthesis; however, all organisms, including you and plants, also respire CO<sub>2</sub> to the atmosphere. If the biosphere has both processes, what is the net change in CO<sub>2</sub> in the air around them? In this lesson, you will answer this question by using a model.
                      </p>

                      <p>
                        You can think of carbon in the atmosphere like a bank account. If you put money in, your bank balance increases. That is a positive flow of money. When you take money out, your balance decreases. That is a negative flow of money. A useful term in systems-thinking is the net flow. The <strong>net flow</strong> is the sum of the amount in and the amount out. If more money is leaving your account than is entering, the net flow is negative. It may be time for some saving! Similarly, carbon moves into and out of the atmosphere.
                      </p>

                      <p>
                        You can think of the atmosphere as a carbon account. Start accounting for the effect of living things on carbon with the following steps. Do the first two steps below as a class, and then continue on your own with the Carbon Flux interactive.
                      </p>

                      <ol>
                        <li>Review the animation you saw in <Link to="unit-2/2.1-moving-carbon#step-9-lesson-2-1">step 9 of Lesson 2.1</Link>. As you watch, note the CO<sub>2</sub> level in air in the Northern Hemisphere throughout the year. What pattern do you see?</li>
                        <li>Copy the table below to your science notebook. Think about the rates of photosynthesis and respiration in different seasons, and then enter "high," "middle," or "low" in each of table cells.</li>

                        <table className="table table-bordered table-hover table-responsive-md studentTable">
                          <thead>
                            <tr className="text-center">
                              <th className="table-active" scope="col"></th>
                              <th className="table-active" scope="col">Winter</th>
                              <th className="table-active" scope="col">Summer</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Respiration (R)</td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td>Photosynthesis (P)</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>

                        <li>
                          Next, use the Carbon Flux interactive to model carbon flow. Open the interactive, and read the following summary: <br />

                          The x-axis shows two seasons: winter and summer. For either, you can increase or decrease the rate of respiration (red) or photosynthesis (green). Respiration is a <strong>source</strong> of CO<sub>2</sub> to the atmosphere. In other words, respiration adds CO<sub>2</sub> to the atmosphere. Photosynthesis removes CO<sub>2</sub>; therefore, relative to the atmosphere, plants are a <strong>sink</strong> for CO<sub>2</sub>. The plants can be in the ocean or on land. The net flow for a season is blue. The net flow for the whole year is the gold bar.
                        </li>
                        <li>
                          In the table, a team of students has decided on the values for photosynthesis and respiration where they live. Did they balance the system? You and a partner can help. Use the Carbon Flux interactive as you complete these steps to determine if the students balanced the system.

                          <table className="table table-bordered table-hover table-responsive-md studentTable">
                            <thead>
                              <tr className="text-center">
                                <th className="table-active" scope="col"></th>
                                <th className="table-active" scope="col">Winter</th>
                                <th className="table-active" scope="col">Summer</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Respiration (R)</td>
                                <td className="text-center">1.5</td>
                                <td className="text-center">3.25</td>
                              </tr>
                              <tr>
                                <td>Photosynthesis (P)</td>
                                <td className="text-center">&#8208;0.25</td>
                                <td className="text-center">&#8208;3.0</td>
                              </tr>
                            </tbody>
                          </table>

                          <ol type="a">
                            <li>Open the interactive and enter the values for photosynthesis and respiration from the table above.</li>
                            <li>
                              For a balanced system, the net flow of CO<sub>2</sub> in the atmosphere is zero. This is shown by adding the value of the two blue bars together. Explain whether the system is balanced. Beside the blue bars, the gold bar ("seasonal difference") gives another way to test for CO<sub>2</sub> balance. <br />

                              If carbon is going to the atmosphere, the gold bar is positive. <br />

                              If carbon is leaving the atmosphere, the gold bar is negative.
                            </li>
                            <li>There are several ways to change values in the model so that the net flow is zero. Explore these with a partner and record your values in your notebook. The students noticed that they could balance the system with respiration in the winter equal to zero (R&#61;0). Test this solution. Explain whether this is a reasonable solution.</li>
                          </ol>
                        </li>
                        <li>
                          During winter, ecosystems in some cold regions have photosynthesis of zero (P&#61;0). Respiration is small, but still above zero (R&gt;0). However, the net flow of CO<sub>2</sub> all year is zero.
                          <ol type="a">
                            <li>What does this tell you about the rates of photosynthesis and respiration in the summer? Select a set of values with the model to demonstrate your thinking.</li>
                            <li>Record the values you used to test this situation.</li>
                          </ol>
                        </li>
                        <li>
                          In the tropics, plants grow quickly all year. However, drought years have much less rainfall than average. Plants grow much less, or even die, under drought stress. At the same time, total respiration can increase due to decay of dead plants by decomposers and bacteria. This happened in 2005 and 2010 in the Amazon Basin.
                          <ol type="a">
                            <li>What would be the net flow of carbon for the atmosphere during a drought year?</li>
                            <li>Use the Carbon Flux interactive to show what photosynthesis and respiration might look like in a drought year. Capture the image or sketch it in your science notebook.</li>
                          </ol>
                        </li>
                      </ol>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop
                ButtonText="Back to Top"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="hawaiian-p-and-r" className="cardHeader">
                      <h2>
                        B. <span className="headerFontBlue">Hawaiian P &amp; R</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        Mauna Loa is a volcano on the Big Island of Hawaii. In Lesson 2.2, you started learning about CO<sub>2</sub> measurements there. At nearly 14,000 feet (about 4,200 m), it rises into the atmosphere near the middle of the Pacific Ocean. Besides telescopes for astronomy, Mauna Loa is home to a research station that has measured CO<sub>2</sub> in the atmosphere for more than 50 years. These data are like the CO<sub>2</sub> data from air bubbles in the Antarctic ice core in Unit 1. An important difference is that Mauna Loa has recorded values for each month.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3"
                            fluid={props.data.image18.childImageSharp.fluid}
                            alt="Mauna Loa, Hawaii, airflow"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        fluid={props.data.image18.childImageSharp.fluid}
                        alt="Mauna Loa, Hawaii, airflow"
                      />

                      <p>
                        Recall that air arriving at Mauna Loa has crossed the Pacific Ocean. Scientists can trace the path of the air through Europe and Asia. Air travels to Mauna Loa from these regions in a few weeks. When that air arrives, the CO<sub>2</sub> signal is a record of photosynthesis (P) and respiration (R) from wherever the air has been&mdash;mid&#8208;latitudes in the Northern Hemisphere.
                      </p>

                      <p>
                        In the following steps, you will use a model to simulate changes in CO<sub>2</sub> in the air from photosynthesis and respiration. Using models like this helps scientists better understand carbon sources and sinks in the carbon cycle.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ CO2MaunaNatural23 }
                            alt="Carbon dioxide, Mauna Loa, natural processes only"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-4"
                        src={ CO2MaunaNatural23 }
                        alt="Carbon dioxide, Mauna Loa, natural processes only"
                      />

                      <ol start="7">
                        <li>
                          Get a printout of the graph. It shows a simulation of changes in CO<sub>2</sub> at Mauna Loa from 2004 to 2010. The pattern is only due to photosynthesis and respiration. You can infer the amount of P versus R for air arriving to Mauna Loa. The human effect is not yet included in the model. You will get that at the end of the lesson.
                          <ol type="a">
                            <li>Look at the x-axis. Label some data points with months for a year. How does CO<sub>2</sub> change over a year from January to December?</li>
                            <li>In what season, or months, is the net flow of CO<sub>2</sub> to the atmosphere positive, or increasing? What does this tell you about <strong>P</strong> versus <strong>R</strong>?</li>
                            <li>In what season, or months, is the net flow of CO<sub>2</sub> to the atmosphere negative, or decreasing? What does this tell you about <strong>P</strong> versus <strong>R</strong>?</li>
                            <li>In what months are <strong>P</strong> and <strong>R</strong> the closest to being balanced (that is, net flow &#8776; 0)?</li>
                          </ol>
                        </li>
                        <li>
                          You will now simulate the relative rates of photosynthesis and respiration for air coming to Mauna Loa. Open the Mauna Loa Biosphere interactive, and read this summary: <br />
                          Along the x-axis are months for two years. For any month, you can increase or decrease respiration (red) or photosynthesis (green). If you change a value in one month, it changes the value for that month in both years. The modeled amounts are graphed in the upper-right. In the lower-right, the modeled CO<sub>2</sub> is in blue, and the "observed," or actual record is in black. It is an annual mean by month from 2004-2010. The net flow of CO<sub>2</sub> for the atmosphere is by month in the lower-left.
                        </li>
                        <li>A team of biologists has been working on the CO<sub>2</sub> record from Mauna Loa. They have measured respiration values by month (see table). Complete the following steps to simulate with the model the levels of photosynthesis for each month:
                        </li>
                      </ol>

                      <table className="table table-bordered table-hover table-responsive-md studentTable">
                        <thead>
                          <tr className="text-center">
                            <th className="table-active" scope="col">Jan</th>
                            <th className="table-active" scope="col">Feb</th>
                            <th className="table-active" scope="col">Mar</th>
                            <th className="table-active" scope="col">Apr</th>
                            <th className="table-active" scope="col">May</th>
                            <th className="table-active" scope="col">Jun</th>
                            <th className="table-active" scope="col">Jul</th>
                            <th className="table-active" scope="col">Aug</th>
                            <th className="table-active" scope="col">Sep</th>
                            <th className="table-active" scope="col">Oct</th>
                            <th className="table-active" scope="col">Nov</th>
                            <th className="table-active" scope="col">Dec</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="text-center">
                            <td>0.9</td>
                            <td>0.9</td>
                            <td>1.2</td>
                            <td>1.6</td>
                            <td>2.4</td>
                            <td>2.7</td>
                            <td>2.8</td>
                            <td>2.8</td>
                            <td>2</td>
                            <td>1.2</td>
                            <td>1</td>
                            <td>0.9</td>
                          </tr>
                        </tbody>
                      </table>

                      <ol start="10">
                        <ol type="a">
                          <li>Add the respiration values to the model for the first six months (January to June).</li>
                          <li>Adjust photosynthesis for the first six months to match the CO<sub>2</sub> data. Get as close a fit (highs and lows) as you can.</li>
                          <li>For the remaining months, do them two months at a time. Add two respiration values, and then adjust the photosynthesis values.</li>
                          <li>Record your values for each month when you have a fit that is reasonably good.</li>
                        </ol>
                        <li>
                          Use these steps to examine your results with a partner:
                          <ol type="a">
                            <li>What is the pattern for respiration during the year?</li>
                            <li>What is the pattern of photosynthesis during the year? How does this compare with the pattern for respiration?</li>
                            <li>In what months is the net flow of carbon into the atmosphere? In what months is the net flow of carbon out of the atmosphere?</li>
                            <li>Using the model, can you match CO<sub>2</sub> patterns in the atmosphere if respiration is zero for a month? You can test this by selecting a month and setting respiration to zero.</li>
                            <li>A research group found a good match with the model (blue) and the observed data (black). This is in the following graph. Critically evaluate the pattern for P and R values and seasonal patterns that you would expect in the biosphere. Explain whether you would accept the model results of this research group.</li>
                          </ol>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image24.childImageSharp.fluid}
                            alt="Respiration, photosynthesis and atmospheric CO2 concentration charts"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                        fluid={props.data.image24.childImageSharp.fluid}
                        alt="Respiration, photosynthesis and atmospheric CO2 concentration charts"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop
                ButtonText="Back to Top"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="the-human-effect" className="cardHeader">
                      <h2>
                        C. <span className="headerFontBlue">The Human Effect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>
                        In section B, the data were only for changes in CO<sub>2</sub> from natural processes: photosynthesis and respiration. The trade-off between these makes it look like the biosphere is "breathing" each year.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ CO21958Mauna25 }
                            alt="Carbon dioxide, Mauna Loa, 1958-2010"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ CO21958Mauna25 }
                        alt="Carbon dioxide, Mauna Loa, 1958-2010"
                      />

                      <p>
                        What other factors affect the CO<sub>2</sub> in your air? From the title of this section, it comes from human activities. The graph shows the actual CO<sub>2</sub> data measured at Mauna Loa for the period 2004-2010. The total curve has natural and human effects. By comparing the two graphs, you can see the difference by adding the contribution by human activities.
                      </p>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3"
                            src={ CO2Mauna26 }
                            alt="Carbon dioxide, Mauna Loa"
                          />
                        </Col>
                      </Row>
                      <img
                        id="unit-2-mauna-loa-co2-chart"
                        className="largeImageCenter img-fluid d-none d-md-block mb-3"
                        src={ CO2Mauna26 }
                        alt="Carbon dioxide, Mauna Loa"
                      />

                      <p>Analyze these data further in the steps below.</p>
                      <ol start="11">
                        <li>
                          Look at the CO<sub>2</sub> data in the graph from Mauna Loa (1958-2010). Complete these sentences about those data. <br />
                          <em>A pattern I see in the graph is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em> <br />
                          <em>The levels of CO<sub>2</sub> have changed from about &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; ppm in 1960 to about &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F; ppm in 2010.</em> <br />
                          <em>These changes show an overall trend for CO<sub>2</sub> that is &#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;&#x0005F;.</em>
                        </li>
                        <li>
                          Open the second Mauna Loa CO<sub>2</sub> interactive. This version lets you include the human effect.
                          <ol type="a">
                            <li>A research team has suggested rates of P &amp; R for air arriving at Mauna Loa. Get their data in the interactive by clicking "best fit." You can also return to values for P &amp; R of 0.0 or 0.5.</li>
                            <li>You can adjust the human effect with the slider. The values on the slider show the rate of change in CO<sub>2</sub> (in ppm) because of humans. Which value best matches the data?</li>
                            <li>How do you adjust values to see only the human effect? In other words, what would you do to turn off the "breathing biosphere"?</li>
                          </ol>
                        </li>
                        <li>Say that the human rate of CO<sub>2</sub> input increased from 1.955 to 2.542. You wish to model how to counteract that increase. Would you do that by changing photosynthesis, respiration, or both in the model? Write your thinking in your notebook.</li>
                      </ol>

                      <div className="col-12">
                        <iframe
                          className="embed-container w-100"
                          src="https://media.bscs.org/carbonconnections/interactives/mauna-loa-biosphere/mauna-loa-biosphere.html"
                          title="Mauna Loa biosphere interactive"
                        />
                      </div>
                      <p>If you are unable to see the interactive, click <a href="https://media.bscs.org/carbonconnections/interactives/mauna-loa-biosphere/mauna-loa-biosphere.html" target="_blank" rel="noopener noreferrer">here&nbsp;<i className="fas fa-external-link-alt"></i></a> to open it in a new tab.</p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop
                ButtonText="Back to Top"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        D. <span className="headerFontBlue">Reflect and Connect</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p>Complete Lesson 2.3 with the following questions.</p>
                      <ol start="14">
                        <li>You can match the CO<sub>2</sub> levels from Mauna Loa with the human contribution at zero. This is shown in the Mauna Loa Biosphere interactive. Do you think that this represents proper use of scientific models?</li>
                        <li>Human use of fossil fuels is part of the carbon cycle. Are these fossil fuels a source or a sink of CO<sub>2</sub> to the atmosphere? Make a diagram in your science notebook to show your answer.</li>
                        <li>
                          A geoscientist is trying to analyze some data that shows CO<sub>2</sub> levels in the atmosphere. The original scientists that recorded the data got a little sloppy and forgot to record the area they were monitoring. The geoscientist knows the data were from either Argentina or Germany, but he is not sure. Use your understanding of processes in the carbon cycle to help the geoscientist figure out the location of these data. Once you have identified the country, describe why you think the data is from that location. <br />
                          <em>Hint: You may need to check a globe to see where Argentina or Germany are located.</em>
                        </li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <img
                            className="rounded img-fluid mb-3 imageBorder"
                            src={ CO2Fraction27 }
                            alt="Carbon dioxide fraction"
                          />
                        </Col>
                      </Row>
                      <img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder mb-3"
                        src={ CO2Fraction27 }
                        alt="Carbon dioxide fraction"
                      />

                      <ol start="17">
                        <li>You can match the CO<sub>2</sub> levels from Mauna Loa with the human contribution at zero. This is shown on the graph. Do you think that this represents proper use of scientific models?</li>
                      </ol>

                      <Row className="d-md-none">
                        <Col>
                          <Img
                            className="rounded img-fluid mb-3 imageBorder"
                            fluid={props.data.image28.childImageSharp.fluid}
                            alt="Carbon dioxide atmospheric concentration chart"
                          />
                        </Col>
                      </Row>
                      <Img
                        className="largeImageCenter img-fluid d-none d-md-block imageBorder"
                        fluid={props.data.image28.childImageSharp.fluid}
                        alt="Carbon dioxide atmospheric concentration chart"
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop
                ButtonText="Back to Top"
              />

              <Row>
                <Col>
                  <Card className="mb-4">
                    <Card.Header id="reflect-and-connect" className="cardHeader">
                      <h2>
                        <span className="headerFontBlue">Explore More: The Green Machine</span>
                      </h2>
                    </Card.Header>
                    <Card.Body className="cardBodyBackground">
                      <p className="lead">The Green Machine</p>
                      <p>
                        Earth is quite a sight from space. A main feature you see is oceans, as about 70 percent of Earth's surface is covered in oceans. You also see a lot of clouds. Between the clouds and oceans, you can see some areas of green and tan. The green regions have many plants and other photosynthesizing organisms.
                      </p>

                      <p>
                        But you've also seen that a lot of photosynthesis and respiration also occurs in the oceans. In fact, about one-third (33 percent) of photosynthesis occurs in oceans. Even though the oceans look blue, they harbor lots of photosynthesizing organisms. These producers are the base of the marine food web. Thus, in considering both land and oceans, Earth is a "Green Machine."
                      </p>

                      <p>
                        Listen to your teacher for directions on watching The Green Machine video. You may have time to view it at the end of class, or on your own. In Lesson 2.4, you will think more about how humans affect the green machine.
                      </p>

                      <div id="" className="embed-container">
                        <iframe title="green_machine" className="videoSetting" src="https://player.vimeo.com/video/307879675" frameBorder="0" webkitallowfullscreen={toString('true')} mozallowfullscreen={toString('true')} allowFullScreen></iframe>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <ButtonTop
                ButtonText="Back to Top"
              />

              <ButtonNext
                ButtonText="2.4: Carbon Cycling"
                NextLink="/unit-2/2.4-carbon-cycling"
              />

            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default TheBreathingBiosphere23

export const query = graphql`
  query {
    image18: file(relativePath: { eq: "student-pages/unit-2/18_map_mauna_loa.jpg" }) {
      ...rowImage
    }
    image22: file(relativePath: { eq: "student-pages/unit-2/22_earth.jpg" }) {
      ...rowImage
    }
    image24: file(relativePath: { eq: "student-pages/unit-2/24_respiration_atmospheric.png" }) {
      ...rowImage
    }
    image28: file(relativePath: { eq: "student-pages/unit-2/28_mauna_scientific_models.png" }) {
      ...rowImage
    }
  }
`
